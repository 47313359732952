import React, { useEffect, useState } from "react";
import { contributorFolderCheck } from "../../../services/contributorServices";
import CustomButton from "../../Common/Buttons/CustomButton";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import "../ContributorSoldImages/contributor.css";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../Common/CustomInput/CustomInput";

const ContributorBrowseFolder = ({ folderName, setFolderName }) => {
  const navigate = useNavigate();
  const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));
  const [disableText,setdisableText]=useState(false);

  const checkFolderName = async () => {
    setdisableText(true);
    try{
    if (folderName) {
      let resp = await contributorFolderCheck({ folder_name: folderName });
      if (resp?.data?.status == 400) {
        toast.error(resp?.data?.message);
      } else if (resp?.data?.status == 200) {
        navigate("/contributor/browseImages?tab=2");
      }
    } else {
      toast.error("Please enter folder name.");
    }}
    catch{
     toast.error("Try Again!")
    }
    setdisableText(false);
  };

  return (
    <>
      <div className="top-reverse-table">
        <Box
          component="div"
          className="account-order-table"
          sx={{
            backgroundColor: "helpTopicsColors.backgroundColor",
          }}
        >
          <Grid container spacing={2} my={2}>
            <Grid item xs={2} mx={1}>
              Folder Name :
            </Grid>
            <Grid item xs={4}>
              <CustomInput
                variant="outlined"
                fullWidth
                name="rank"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  classes: {
                    notchedOutline: "rounded",
                  },
                }}
                onChange={(e) => setFolderName(e.target.value)}
                inputProps={{
                  style: {
                    padding: "8px 10px",
                    fontSize: 14,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CustomButton
              className="commonButtonStyles"
              sx={{
                borderRadius: (theme) => theme.shape.borderRadiusSecondary(15),
                margin: "10px",
              }}
              onClick={() => navigate("/contributor/folderList")}
            >
              Cancel
            </CustomButton>
            <CustomButton
              className="commonButtonStyles"
              type="submit"
              disabled={disableText}
              sx={{
                borderRadius: (theme) => theme.shape.borderRadiusSecondary(15),
                margin: "10px",
              }}
              onClick={() => {
                checkFolderName();
              }}
            >
              Next
            </CustomButton>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default ContributorBrowseFolder;
