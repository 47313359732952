import React, { useEffect, useState } from "react";
import { DATA_LIMIT } from "../../../data/constants";
import { useDispatch, useSelector } from "react-redux";
import ApiConfig, {
  BASE_IMAGE_URL,
  S3_BASE_URL,
} from "../../../config/ApiConfig";
import CustomButton from "../../Common/Buttons/CustomButton";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import "../ContributorSoldImages/contributor.css";
import { useMemo } from "react";
import Pagination from "../../Common/pagination/Pagination";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DeleteConfirmationDialog from "../../Modals/DeleteConfirmationDialog";
import { GetContributorUploadImages,RemoveContributorImage } from "../../../services/imageUpload";
import { UploadImagesDataSelector } from "../../../redux/contributorUploadImages";
import { toast } from "react-toastify";
import AssignNocModal from "../../Modals/AssignNocModal";
import {
  DeleteContributorNoc,
  GetAllNocListingWithoutRedux,
} from "../../../services/contributorNocs";

const ContributorUploadImages = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const limit = DATA_LIMIT;
  const location = useLocation();
  const folder = location.state;
  const { UploadImagesData } = useSelector(UploadImagesDataSelector);
  const [pageLimit, setPageLimit] = useState(10);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [search, setSearch] = useState("");
  const [folderName, setFolderName] = useState(folder);
  const [imageName, setImageName] = useState("");
  const [contributorId, setContributorId] = useState("");
  const dispatch = useDispatch();
  const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));
  const [selectedItems, setSelectedtems] = useState([]);
  const [assignOpen, setAssignOpen] = useState(false);
  const [nocList, setNocList] = useState([]);

  useEffect(() => {
    GetSearchResults(page);
  }, []);

  useEffect(() => {
    GetSearchResults(page);
  }, [pageLimit]);

  const dashboardHeader = [
    {
      label: "",
      id: 1,
    },
    {
      label: "Image Name",
      id: 2,
    },
    {
      label: "Status",
      id: 3,
    },
    {
      label: "Folder Name",
      id: 4,
    },
    {
      label: "Rejected Reason",
      id: 5,
    },
    {
      label: "NOC",
      id: 6,
    },
    {
      label: "Action",
      id: 7,
    },
  ];

  useEffect(() => {
    nocListData();
  }, []);

  const nocListData = async () => {
    let data = await GetAllNocListingWithoutRedux();
    setNocList(data?.rows);
  };

  const currentPageFunction = (param) => {
    if (param?.fromPageVal) {
      GetSearchResults(param?.selected + 1);
    } else {
      if (param?.isPrevious) {
        if (page > 1) GetSearchResults(page - 1);
      }
      if (param?.isNext) {
        GetSearchResults(page + 1);
      }
    }
  };
  const setPageLimitfunction = async (page) => {
    setPageLimit(page);
    setPage(1);
  };
  const handlePageChange = (page) => {
    GetSearchResults(page);
  };

  const GetSearchResults = async (page = 1) => {
    setIsLoadingImages(true);
    const data = JSON.parse(localStorage.getItem("contibuter_data"));
    if (data) {
      const payload = { page: page, limit: pageLimit };
      if (search) {
        payload["search"] = search;
      }
      if (folderName) {
        payload["folder_name"] = folderName;
      }
      let response = await dispatch(GetContributorUploadImages(payload));
      try {
        if (response?.status === 200) {
          if (response?.data?.count > 0) {
            // setSearchPageNumber(searchPageNumber + 1);
            // setFinalData([...finalData, ...response?.data?.rows]);
            setIsLoadingImages(false);
          } else {
            setIsLoadingImages(false);
          }
        } else {
          setIsLoadingImages(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoadingImages(false);
      }
    }
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };
  const handleDelteConfirmation = async () => {
    try {
      const payload = {
        image_name: imageName,
      };
      // await dispatch(DeleteContributorNoc(payload));
      await dispatch(RemoveContributorImage(payload));
      setOpenConfirmationDialog(false);
      GetSearchResults(page);
    } catch (e) {
      toast.error(`Something went wrong. Please try again.`, {
        autoClose: 2500,
      });
    }
    setOpenConfirmationDialog(false);
  };

  const onSelectAllClick = (img) => {
    let imgExist = selectedItems?.findIndex(
      (item) =>
        item?.image_name == img?.image_name &&
        item?.contributor_upload_image_id == img?.contributor_upload_image_id
    );
    if (imgExist >= 0) {
      let newArray = selectedItems?.filter((item, index) => index != imgExist);
      setSelectedtems(newArray);
    } else {
      setSelectedtems((prevItems) => [...prevItems, img]);
    }
  };

  const checkIfItemExist = (img) => {
    let imgExist = selectedItems?.findIndex(
      (item) =>
        item?.image_name == img?.image_name &&
        item?.contributor_upload_image_id == img?.contributor_upload_image_id
    );
    if (imgExist >= 0) return true;
    else return false;
  };

  const handleAllCheck = () => {
    let length = selectedItems?.length;
    if (length < UploadImagesData?.items?.rows?.length)
      setSelectedtems(UploadImagesData?.items?.rows);
    else setSelectedtems([]);
  };

  const handleOpen = (val = false) => {
    setAssignOpen(val);
    setSelectedtems([]);
  };

  return (
    <>
      <Grid container spacing={2} my={2}>
        <Grid item xs={12} textAlign={"right"}>
          <CustomButton
            className="commonButtonStyles"
            onClick={() => {
              navigate("/contributor/browseImages");
            }}
            // type="submit"
          >
            <Typography
              variant="mainpagetitle"
              component="div"
              style={{ fontSize: 16, padding: 5 }}
            >
              Upload
            </Typography>
          </CustomButton>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <CustomButton
              className="commonButtonStyles"
              sx={{
                borderRadius: (theme) => theme.shape.borderRadiusSecondary(15),
                marginLeft: "10px",
                marginRight: "10px",
              }}
              style={{ right: "92%" }}
            >
              <Typography
                variant="sectiondata"
                className="font-white"
                component="div"
              >
                <Link
                  to="/contributor/folderList"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  Back
                </Link>
              </Typography>
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
      {isLoadingImages ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <Grid sx={{ color: "grey.500" }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Grid>
        </div>
      ) : UploadImagesData?.items?.rows?.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: "30px" }}>No Data</div>
      ) : (
        <div className="top-reverse-table">
          <Box
            component="div"
            className="account-order-table"
            sx={{
              backgroundColor: "helpTopicsColors.backgroundColor",
            }}
          >
            <Box
              component="div"
              sx={{
                display: "flex",
                justifyContent: "space-between", // Use space-between to align items
                alignItems: "center",
                width: "100%", // Ensure the Box takes the full width
              }}
            >
              {selectedItems?.length > 0 && (
                <CustomButton
                  className="commonButtonStyles"
                  sx={{
                    borderRadius: (theme) =>
                      theme.shape.borderRadiusSecondary(15),
                    margin: "10px",
                  }}
                  onClick={(e) => setAssignOpen(true)}
                >
                  <Typography
                    variant="sectiondata"
                    className="font-white"
                    component="div"
                  >
                    Assign NOC for {selectedItems?.length} Images
                  </Typography>
                </CustomButton>
              )}

              <CustomButton
                className="commonButtonStyles"
                sx={{
                  borderRadius: (theme) =>
                    theme.shape.borderRadiusSecondary(15),
                  margin: "10px",
                }}
              >
                <Typography
                  variant="sectiondata"
                  className="font-white"
                  component="div"
                >
                  Total Records:{" "}
                  {Math.ceil(UploadImagesData?.items?.count) || 0}
                </Typography>
              </CustomButton>
            </Box>
            <Table aria-label="Pending Orders">
              <TableHead className="background-light-gray">
                <TableRow>
                  {dashboardHeader?.map((data, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          borderBottom: "none",
                          width: "10%",
                        }}
                      >
                        {data?.id == 1 ? (
                          <>
                            <Checkbox
                              color="primary"
                              indeterminate={
                                UploadImagesData?.items?.rows?.length >
                                  selectedItems?.length &&
                                selectedItems?.length > 0
                              }
                              checked={
                                UploadImagesData?.items?.rows?.length ==
                                selectedItems?.length
                              }
                              onChange={() => handleAllCheck()}
                              inputProps={{
                                "aria-label": "checkbox",
                              }}
                            />
                          </>
                        ) : (
                          <Typography
                            component="div"
                            variant="mainsectiontitle"
                            className="alignText-center boldFont"
                          >
                            {data?.label == "Royalty"
                              ? `Royalty(${contributorData?.percentage || 0})%`
                              : data?.label}
                          </Typography>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {UploadImagesData?.items?.rows?.map((img, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: "inherit",
                      }}
                    >
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 2,
                        }}
                      >
                        <Checkbox
                          color="primary"
                          // indeterminate={numSelected > 0 && numSelected < rowCount}
                          checked={checkIfItemExist(img)}
                          onChange={() => onSelectAllClick(img)}
                          inputProps={{
                            "aria-label": "checkbox",
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          <Link
                            className="textDecoration-none link-text"
                            to={
                              img?.folder_name != null
                                ? `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.folder_name}/${img?.image_name}`
                                : `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.image_name}`
                            }
                            target={"_blank"}
                          >
                            <img
                              style={{ maxWidth: 200, maxHeight: 200 }}
                              src={
                                img?.folder_name != null
                                  ? `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.folder_name}/${img?.image_name}`
                                  : `${S3_BASE_URL}contributor/${img?.contributor_id}/${img?.image_name}`
                              }
                            />
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                          sx={{
                            ...(img?.status == 1
                              ? { color: "success.main" }
                              : img?.status == 2
                              ? { color: "danger.main" }
                              : img?.status == 0
                              ? { color: "info.main" }
                              : {}),
                            whiteSpace: "nowrap",
                          }}
                        >
                          {img?.status === 0
                            ? "Pending"
                            : img?.status === 1
                            ? "Approved"
                            : "Rejected"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {img?.folder_name !== null ? img?.folder_name : "N/A"}
                        </Typography>
                      </TableCell>

                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                          {img?.comment ? img?.comment : "N/A"}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                        }}
                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                        >
                       
                          {/* {img?.image_nocs?.[0]?.noc_name
                            ? img?.image_nocs?.[0]?.noc_name
                            : "N/A"} */}
                            {img?.concatenatedNocNames?img?.concatenatedNocNames:'N/A'}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 0,
                          width: "40%",
                        }}
                      >
                        {img?.status == "0" ? (
                          <Typography
                            component="div"
                            variant="sectiondata"
                            className="alignText-center boldFont"
                          >
                            <CustomButton
                              className="commonButtonStyles"
                              sx={{
                                borderRadius: (theme) =>
                                  theme.shape.borderRadiusSecondary(15),
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                              onClick={() => {
                                setImageName(img?.image_name);
                                setContributorId(img?.contributor_id);
                                setOpenConfirmationDialog(true);
                              }}
                            >
                              <Typography
                                variant="sectiondata"
                                className="font-white"
                                component="div"
                              >
                                Delete
                              </Typography>
                            </CustomButton>
                          </Typography>
                        ) : null}
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"
                          style={{ marginTop: "12px" }}
                        >
                          <CustomButton
                            className="commonButtonStyles"
                            sx={{
                              borderRadius: (theme) =>
                                theme.shape.borderRadiusSecondary(15),
                              marginLeft: "10px",
                              marginRight: "10px",
                            }}
                            onClick={() => {
                              setAssignOpen(true);
                              setSelectedtems([img]);
                            }}
                          >
                            <Typography
                              variant="sectiondata"
                              className="font-white"
                              component="div"
                            >
                              Assign Noc
                            </Typography>
                          </CustomButton>
                        </Typography>
                        {/* </div> */}
                      </TableCell>
                      {openConfirmationDialog === true && (
                        <DeleteConfirmationDialog
                          open={openConfirmationDialog}
                          onClose={handleCloseConfirmationDialog}
                          imageLink={
                            img?.folder_name != ""
                              ? `${S3_BASE_URL}contributor/${contributorId}/${img?.folder_name}/${imageName}`
                              : `${S3_BASE_URL}contributor/${contributorId}/${imageName}`
                          }
                          onConfirmation={handleDelteConfirmation}
                        />
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </div>
      )}

      <Pagination
        itemsPerPage={pageLimit}
        handlePageChange={handlePageChange}
        page={page}
        items={UploadImagesData?.items?.count}
        currentPageFunction={currentPageFunction}
        setPageLimitfunction={setPageLimitfunction}
      />
      {assignOpen && (
        <AssignNocModal
          open={assignOpen}
          handleOpen={handleOpen}
          getListing={GetSearchResults}
          ImageData={selectedItems}
          nocList={nocList}
        />
      )}
    </>
  );
};

export default ContributorUploadImages;
