
import ApiConfig from "../config/ApiConfig";
import { STATUS_200, STATUS_300 } from "../data/constants";
import {  deleteApiPayload, errorMessage } from "../lib/helper";
import { toast } from "react-toastify";
import contributoraxois from "../AxiosInterceptor";
import { setNocData,setNocDataError,setLoading } from "../redux/contributorNocImages";
// import { setNocData, setNocDataError,setLoading } from "../redux/contributorNocImages";

export const GetAllNocListing = (payload) => {
  if (payload !== undefined) {
    return async (dispatch) => {
      try {
        dispatch(setLoading(true));
        const resp = await contributoraxois.post(`${ApiConfig.constributorNocListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
        
            dispatch(setNocData(resp?.data?.data));
            dispatch(setLoading(false));
        } else {
          throw resp;
        }
      } catch (e) {
          dispatch(
            setNocDataError({
              isError: true,
              message: errorMessage(e),
            })
          );
        
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
        dispatch(setLoading(false));
      }
    };
  }
};

export const GetAllNocListingWithoutRedux = async(payload) => {
      try {
        const resp = await contributoraxois.post(`${ApiConfig.constributorNocListing}`, payload);
        if (
          resp?.data?.status >= STATUS_200 &&
          resp?.data?.status < STATUS_300
        ) {
            return resp?.data?.data
        } else {
          throw resp;
        }
      } catch (e) {
         
        console.log(e)
        toast.error(errorMessage(e), {
          autoClose: 2000,
        });
      }
};

export const AssignNocToImages = async(payload) => {
  try {
    const resp = await contributoraxois.post(`${ApiConfig.assignNocsToImage}`, payload);
    if (
      resp?.data?.status >= STATUS_200 &&
      resp?.data?.status < STATUS_300
    ) {
      toast.success("NOC is Assigned successfully.", {
        autoClose: 2000,
      });
        return resp?.data?.data
    } else {
      throw resp;
    }
  } catch (e) {
     
    console.log(e)
    toast.error(errorMessage(e), {
      autoClose: 2000,
    });
  }
};
export const DeleteContributorNoc = async(payload) => {
  if (payload !== undefined) {
          try {
              const resp = await contributoraxois.delete(
                  `${ApiConfig.deleteNoc}`,
                  deleteApiPayload(payload)
              );
              if (
                  resp?.data?.status >= STATUS_200 &&
                  resp?.data?.status < STATUS_300
              ) {
                  toast.success(resp?.data?.data, {
                      autoClose: 2500,
                  });
              } else {
                  throw resp;
              }
          } catch (e) {
              toast.error(errorMessage(e), {
                  autoClose: 2500,
              });
          }
  }
};