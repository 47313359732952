import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
 NocData : {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};

export const ImagesNocDataSlice = createSlice({
  name: "NocData",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNocData: (state, action) => {
      state.NocData.items = action.payload;
      state.NocData.totalCount = action.payload?.length;
    },
    setNocDataError: (state, action) => {
      state.NocData.error = action.payload;
      state.NocData.items = [];
      state.NocData.totalCount = 0;
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setNocData,
  setNocDataError,

} = ImagesNocDataSlice.actions;

export default ImagesNocDataSlice.reducer;

export const NocDataSelector = (state) => state.NocData;
