import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../../data/constants';
import { ContributorHistoryOfSoldImages, GetAllConributorImageData, contributorFindImages } from '../../../services/contributorServices';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_IMAGE_URL } from '../../../config/ApiConfig';
import { GetAllSoldImages } from '../../../services/contributorSoldImages';
import { ReactComponent as LeftArrow } from "../../Assets/svgs/left-arrow-icon.svg"
import { ReactComponent as RightArrow } from "../../Assets/svgs/right-arrow-icon.svg";
import { ReactComponent as ModalCross } from "../../Assets/svgs/modal-cross.svg";
import CustomButton from '../../Common/Buttons/CustomButton';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { purple, red } from '@mui/material/colors';
import { SoldImagesDataSelector } from '../../../redux/contributorSoldImages';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { currencyConversion, formatDate } from '../../../lib/helper';
import CustomInput from '../../Common/CustomInput/CustomInput';
import './contributor.css';
import { Link, useParams } from 'react-router-dom';
import { SoldImagesHistoryDataSelector } from '../../../redux/contributorSoldImagesHistory';
import Pagination from '../../Common/pagination/Pagination';
const ContributorSoldImagesHistory = () => {
  const [page, setPage] = useState(1);
  const limit = DATA_LIMIT;

  const { SoldImagesData } = useSelector(SoldImagesDataSelector);
  const { SoldImagesHistory } = useSelector(SoldImagesHistoryDataSelector);
  const { id } = useParams();
  const [pageLimit, setPageLimit] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [finalData, setFinalData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [starDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const dispatch = useDispatch();
  const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));

  useEffect(() => {
    // getAllImages();
    GetSearchResults(page);
  }, [starDate, endDate, pageLimit, page])


  const dashboardHeader = [

    {
      label: "Image Name",
      id: 1,
    },
    {
      label: "Date",
      id: 2,
    },
    {
      label: "Order Id",
      id: 3,
    },
    {
      label: "Payment Status",
      id: 4,
    },
    // {
    //   label: "Paid On",
    //   id: 5,
    // },
    {
      label: "Image Size",
      id: 6,
    },
    {
      label: "Price",
      id: 7,
    },
    {
      label: "Discount",
      id: 8,
    },
    {
      label: "Total Amount",
      id: 9,
    },
    {
      label: "Royalty",
      id: 10,
    },
    // {
    //   label: "Payment mode",
    //   id: 11,
    // },
    // {
    //   label: "Paid On",
    //   id: 12,
    // },



  ];

  const GetSearchResults = async (page) => {
    setPage(page);
    setIsLoadingImages(true);
    const data = JSON.parse(localStorage.getItem("contibuter_data"));
    if (data) {
      const payload = { contributor_payment_id: id, page: page, limit: pageLimit };
      // if (starDate && endDate) {
      //   payload['start_date'] = dayjs(starDate)?.format('YYYY-MM-DD') + " 00:00:00.000";
      //   payload['end_date'] = dayjs(endDate)?.format('YYYY-MM-DD') + ' 00:00:00.000';
      // }
      // if(search){
      //   payload['search']=search;
      // }
      // if(paymentType){
      //   //payment_type
      //   payload['payment_type']=paymentType;
      // }
      let response = await dispatch(ContributorHistoryOfSoldImages(payload));

      try {
        if (response?.status === 200) {
          if (response?.data?.rows?.length > 0) {
            setSearchPageNumber(searchPageNumber + 1);
            setFinalData([...finalData, ...response?.data?.rows]);
            setIsLoadingImages(false);
          } else {
            setIsLoadingImages(false);
          }
        } else {
          setIsLoadingImages(false);
        }
        if (
          response?.data?.hits?.total?.value === finalData.length ||
          response?.data?.hits?.total?.value <= limit
        ) {
          setHasMore(false);
        } else {

          let documentHeight = document.body.scrollHeight;
          let currentScroll = window.scrollY + window.innerHeight;
          let modifier = 200;
          if (currentScroll + modifier > documentHeight) {
            window.scrollTo(0, window.pageYOffset - 200);
          }
          setHasMore(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const showConfirmedPreviousData = () => {
    if (page !== 1) {
      setPage(page - 1);
      GetSearchResults(page - 1);
    }
  };
  const showConfirmedNextData = () => {
    setPage(page + 1);
    GetSearchResults(page + 1);
  };

  const makeImageUrl = (image, rank, shootid) => {
    return `${BASE_IMAGE_URL}${shootid}/${rank}-${image}.jpg`
  };

  const handlePaymentMode = (e) => {
    // console.log(e.target.value);
  }

  const currentPageFunction = (param) => {
    if (param?.fromPageVal) {
      GetSearchResults(param?.selected + 1);
    }
    else {
      if (param?.isPrevious) {
        if (page > 1)
          GetSearchResults(page - 1);
      }
      if (param?.isNext) {
        GetSearchResults(page + 1);
      }
    }
  }
  const setPageLimitfunction = async (page) => {
    setPageLimit(page);
    setPage(1);
  }
  const handlePageChange = (page) => {
    GetSearchResults(page)
  }

  return (
    <>
      {isLoadingImages ?
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <Grid sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Grid>
        </div>
        : SoldImagesHistory?.items?.rows?.length === 0 ? <>
          <>

            <div className="top-reverse-table">
              <Box
                component="div"
                className="account-order-table"
                sx={{
                  backgroundColor: "helpTopicsColors.backgroundColor",
                }}
              >

                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <CustomButton
                    className="commonButtonStyles"
                    sx={{
                      borderRadius: (theme) =>
                        theme.shape.borderRadiusSecondary(15),
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                    style={{ right: '92%' }}
                  >
                    <Typography
                      variant="sectiondata"
                      className="font-white"
                      component="div"

                    >
                      <Link to="/contributor/payments?tab=1" style={{ textDecoration: 'none', color: 'white' }}>
                        Back
                      </Link>

                    </Typography>
                  </CustomButton>



                </Box>
                <Table aria-label="Pending Orders">
                  <TableHead className="background-light-gray">
                    <TableRow>
                      {dashboardHeader?.map((data, index) => {
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              borderBottom: "none",
                            }}
                          >
                            <Typography
                              component="div"
                              variant="mainsectiontitle"
                              className="alignText-center boldFont"
                            >
                              {data?.label == "Royalty" ? `Royalty(${contributorData?.percentage || 0})%` : data?.label}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody  >
                    {/* <div style={{ textAlign: "center", marginTop: "5px" }}>
            No Data
          </div> */}
                    <TableRow
                      style={{ backgroundColor: '#FFFFF', color: 'white' }}
                      sx={{
                        backgroundColor: "inherit",

                      }}
                    >
                      <TableCell
                        sx={{
                          backgroundColor: "inherit",
                          "&:hover": { backgroundColor: "inherit" },
                          padding: 1,

                        }}

                      >
                        <Typography
                          component="div"
                          variant="sectiondata"
                          className="alignText-center boldFont"


                        >
                          No Data

                        </Typography>
                      </TableCell>
                    </TableRow>

                  </TableBody>

                </Table>
              </Box>
            </div>

          </>
        </>

          :
          <>

            {SoldImagesHistory?.items?.rows?.length > 0 &&
              <div className="top-reverse-table">
                <Box
                  component="div"
                  className="account-order-table"
                  sx={{
                    backgroundColor: "helpTopicsColors.backgroundColor",
                  }}
                >

                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      className="commonButtonStyles"
                      sx={{
                        borderRadius: (theme) =>
                          theme.shape.borderRadiusSecondary(15),
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                      style={{ right: '72%' }}
                    >
                      <Typography
                        variant="sectiondata"
                        className="font-white"
                        component="div"

                      >
                        <Link to="/contributor/payments?tab=1" style={{ textDecoration: 'none', color: 'white' }}>
                          Back
                        </Link>

                      </Typography>
                    </CustomButton>

                    {/* <IconButton
                      className="ArrowIconButton"
                      disabled={page === 1 ? true : false}
                      onClick={() => showConfirmedPreviousData()}
                    >
                      <LeftArrow
                        className={`${page === 1 ? "disabled-arrow" : ""
                          } left-right-arrow`}
                      />
                    </IconButton> */}
                    <CustomButton
                      className="commonButtonStyles"
                      sx={{
                        borderRadius: (theme) =>
                          theme.shape.borderRadiusSecondary(15),
                        // marginLeft: "10px",
                        // marginRight: "10px",
                        margin: '10px'
                      }}

                    >
                      <Typography
                        variant="sectiondata"
                        className="font-white"
                        component="div"
                      >

                        Total Record:{Math.ceil(SoldImagesHistory?.items?.count) || 0}

                      </Typography>
                    </CustomButton>
                    {/* <IconButton
                      disabled={
                        page ===
                          Math.ceil(SoldImagesHistory?.items?.count / pageLimit)
                          ? true
                          : false
                      }
                      className="ArrowIconButton"
                      onClick={() => showConfirmedNextData()}
                    >
                      <RightArrow
                        className={`${page ===
                          Math.ceil(
                            SoldImagesHistory?.items?.count / pageLimit
                          )
                          ? "disabled-arrow"
                          : ""
                          } left-right-arrow`}
                      />
                    </IconButton> */}
                  </Box>
                  <Table aria-label="Pending Orders">
                    <TableHead className="background-light-gray">
                      <TableRow>
                        {dashboardHeader?.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                backgroundColor: "inherit",
                                "&:hover": { backgroundColor: "inherit" },
                                borderBottom: "none",
                              }}
                            >
                              <Typography
                                component="div"
                                variant="mainsectiontitle"
                                className="alignText-center boldFont"
                              >
                                {data?.label == "Royalty" ? `Royalty(${contributorData?.percentage || 0})%` : data?.label}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {SoldImagesHistory?.items?.rows?.map((img, index) => {
                        let manageColor = '#FFFFFF';
                        let paidColor = img?.paid ? '#12B347' : '#993300';
                        return <TableRow key={index}
                          style={{ backgroundColor: manageColor, color: 'white', }}
                          sx={{
                            backgroundColor: "inherit",
                          }}
                        >
                          {/* <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <img className='img-fluid' style={{ width: '150px' }} src={makeImageUrl(img?.image_name, img?.rank, img?.shootid)} />
                          </TableCell> */}
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.image_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.paid_on ? dayjs(img?.paid_on)?.format("DD MMM YYYY") : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.order_id}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                              style={{ backgroundColor: 'success', color: paidColor }}

                            >
                              {img?.paid ? 'Paid' : 'Unpaid'}
                            </Typography>
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                              style={{ backgroundColor: 'success', color: paidColor }}
                            >

                              {img?.paid_on ? ` ${formatDate(img?.paid_on, 'dd/mm/yyyy')}` : ''}
                            </Typography>
                          </TableCell>

                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.quality}-{img?.image_type}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {currencyConversion(img?.amount)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.Discount}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {/* {img?.order?.order_status === '1' ? '' : '-'} {img?.amount - img?.Discount} */}
                              {currencyConversion(img?.amount - img?.Discount)}

                            </Typography>

                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.royalty?currencyConversion(img?.royalty) :'-' || "-"}
                            </Typography>
                          </TableCell>


                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </div>
            }
            <Pagination
              itemsPerPage={pageLimit}
              handlePageChange={handlePageChange}
              page={page}
              items={SoldImagesHistory?.items?.count}
              currentPageFunction={currentPageFunction}
              setPageLimitfunction={setPageLimitfunction}
            />
          </>
      }
    </>
  );

}

export default ContributorSoldImagesHistory
