import React, { useEffect, useState } from 'react';
import { DATA_LIMIT } from '../../../data/constants';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_IMAGE_URL } from '../../../config/ApiConfig';
import { GetAllSoldImages } from '../../../services/contributorSoldImages';
import CustomButton from '../../Common/Buttons/CustomButton';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { purple, red } from '@mui/material/colors';
import { SoldImagesDataSelector } from '../../../redux/contributorSoldImages';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { currencyConversion, formatDate } from '../../../lib/helper';
import CustomInput from '../../Common/CustomInput/CustomInput';
import './contributor.css';
import { useMemo } from 'react';
import Pagination from '../../Common/pagination/Pagination';


const ContributorSoldImages = () => {
  const [page, setPage] = useState(1);
  const limit = DATA_LIMIT;
  const { SoldImagesData } = useSelector(SoldImagesDataSelector);
  const [pageLimit, setPageLimit] = useState(200);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [isLoadingImages, setIsLoadingImages] = useState(true);
  const [starDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [search, setSearch] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const dispatch = useDispatch();
  const contributorData = JSON.parse(localStorage.getItem("contibuter_data"));

  useEffect(() => {
    // getAllImages();
    GetSearchResults(page);
  }, [starDate, endDate, pageLimit])


  const dashboardHeader = [
    {
      label: "",
      id: 1,
    },
    {
      label: "Image Name",
      id: 1,
    },
    {
      label: "Date",
      id: 2,
    },
    {
      label: "Order Id",
      id: 3,
    },
    {
      label: "Payment Status",
      id: 4,
    },
    // {
    //   label: "Paid On",
    //   id: 5,
    // },
    {
      label: "Image Size",
      id: 6,
    },
    {
      label: "Price",
      id: 7,
    },
    {
      label: "Discount",
      id: 8,
    },
    {
      label: "Total Amount",
      id: 9,
    },
    {
      label: "Royalty",
      id: 10,
    },
    // {
    //   label: "Payment mode",
    //   id: 11,
    // },
    // {
    //   label: "Paid On",
    //   id: 12,
    // },



  ];

  const { totalPrice, totalDiscount, totalAmount, totalRoyality } = useMemo(() => {
    try {
      return {
        totalPrice: SoldImagesData?.items?.priceTotal||0,
        totalDiscount: SoldImagesData?.items?.discounTotal||0,
        totalAmount: SoldImagesData?.items?.totalAmount||0,
        totalRoyality: SoldImagesData?.items?.royaltyTotal ||0 
      }
    } catch (e) {
      return {
        totalPrice: 0,
        totalDiscount: 0,
        totalAmount: 0,
        totalRoyality: 0
      }
    }
  }, [SoldImagesData?.items?.rows])

  const GetSearchResults = async (page) => {
    setIsLoadingImages(true);
    setPage(page);
    const data = JSON.parse(localStorage.getItem("contibuter_data"));
    if (data) {
      const payload = { page: page, limit: pageLimit };
      if (starDate && endDate) {
        payload['start_date'] = dayjs(starDate)?.format('YYYY-MM-DD') + " 00:00:00";
        payload['end_date'] = dayjs(endDate)?.format('YYYY-MM-DD') + ' 23:59:59';
      }
      if (search) {
        payload['search'] = search;
      }
      if (paymentType) {
        //payment_type
        payload['payment_type'] = paymentType;
      }
      try{
        setIsLoadingImages(true);
      let response = await dispatch(GetAllSoldImages(payload));
      setIsLoadingImages(false);
      }
      catch(err){
       console.log(err);
       setIsLoadingImages(false);
      }
    }
  };

  const makeImageUrl = (image, rank, shootid) => {
    return `${BASE_IMAGE_URL}${shootid}/${rank}-${image}.jpg`
  };

  const handlePaymentMode = (e) => {
    // console.log(e.target.value);
  }

  const currentPageFunction = (param) => {
    if (param?.fromPageVal) {
      GetSearchResults(param?.selected + 1);
    }
    else {
      if (param?.isPrevious) {
        if (page > 1)
          GetSearchResults(page - 1);
      }
      if (param?.isNext) {
        GetSearchResults(page + 1);
      }
    }
  }
  const setPageLimitfunction = async (page) => {
    setPageLimit(page);
    setPage(1);
  }
  const handlePageChange = (page) => {
    GetSearchResults(page)
  }

  return (
    <>
      <Grid container spacing={2} my={2}>
        <Grid item xs={2} className='soldHeader' >
          <LocalizationProvider dateAdapter={AdapterDayjs}  >

            <DesktopDatePicker
              closeOnSelect={true}
              label={'From Date'}
              isBefore={true}
              value={starDate}
              onChange={(newDate) => {
                setStartDate(newDate);
                setPage(1)
              }}
              renderInput={(params) => <TextField sx={{
                "& .MuiInputBase-input": {
                  height: "10px", // Set your height here.
                  fontSize: '10px'

                }
              }}
                {...params} />}
              inputFormat="DD MMM YYYY"
              disableFuture={true}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2} className='soldHeader'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>

            <DesktopDatePicker
              closeOnSelect={true}
              label={'To Date'}
              isBefore={true}
              value={endDate}
              onChange={(newDate) => {
                setEndDate(newDate);
                setPage(1);
              }}
              renderInput={(params) => <TextField {...params} />}
              inputFormat="DD MMM YYYY"
              disableFuture={true}
            />
          </LocalizationProvider>

        </Grid>
        <Grid item xs={2} className='soldHeaderSearch'>
          <CustomInput
            variant="outlined"
            fullWidth
            name="search"
            placeholder="Enter..."
            label="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}

            InputLabelProps={{ shrink: true }}
            InputProps={{
              classes: {
                notchedOutline: 'rounded',
              },
            }}
          // inputProps={{
          //   style: {
          //     padding: "16px 8px",
          //     fontSize: 12
          //   }
          // }}
          />
        </Grid>
        <Grid item xs={2} className='soldHeaderDropdown'>
          <FormControl fullWidth
          // inputProps={{
          //       style: {
          //         padding: "12px 8px",
          //         fontSize: 12
          //       }
          //     }}
          >
            <CustomInput
              labelId="demo-simple-select-label"
              id="payment_type"
              value={paymentType}
              label="Payment Type"
              onChange={(e) => setPaymentType(e.target.value)}
              className="dense"
              SelectProps={{
                native: true,
                classes: {
                  select: "dense"
                },
              }}
              select
              InputLabelProps={{
                shrink: true, style: {
                }
              }}
              InputProps={{
                classes: {
                  notchedOutline: 'rounded',
                },
                style: {
                  height: 46,
                }
              }}
            >
              <option value={'all'}>All</option>
              <option value={'1'}>Paid</option>
              <option value={'0'}>Unpaid</option>
            </CustomInput>
          </FormControl>
        </Grid>
        <Grid item xs={2} textAlign={'right'}>
          <CustomButton

            className="commonButtonStyles"
            onClick={() => { GetSearchResults(1); setPage(1); }}
            type="submit"
          >
            <Typography variant="mainpagetitle" component="div" style={{ fontSize: 16, padding: 5 }}>
              Apply
            </Typography>
          </CustomButton>
        </Grid>
      </Grid>


      <Grid container spacing={2} >
        <Grid item xs={2} className='soldHeader' >
          <Typography
            variant="subtitle1"
            // className="font-white"
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            {/* Total price:{currencyConversion(SoldImagesData?.items?.priceTotal ? SoldImagesData?.items?.priceTotal : 0)} */}
            Total price:{currencyConversion(totalPrice)}

          </Typography>
        </Grid>
        <Grid item xs={2} className='soldHeader'>
          <Typography
            variant="subtitle1"
            // className="font-white"
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            Discount:{currencyConversion(totalDiscount)}
          </Typography>
        </Grid>
        <Grid item xs={2} className='soldHeaderSearch'>
          <Typography
            variant="subtitle1"
            // className="font-white"
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            {/* Royalty:{currencyConversion(SoldImagesData?.items?.royaltyTotal ? SoldImagesData?.items?.royaltyTotal : 0)} */}
            Royalty:{currencyConversion(totalRoyality)}

          </Typography>
        </Grid>
        <Grid item xs={6} className='soldHeaderDropdown'>
          <Typography
            variant="subtitle1"
            // className="font-white"
            sx={{ fontWeight: 'bold' }}
            component="div"
          >
            {/* Total Amount:{currencyConversion(SoldImagesData?.items?.totalAmount ? SoldImagesData?.items?.totalAmount : 0)} */}
            Total Amount:{currencyConversion(totalAmount)}

          </Typography>
        </Grid>

      </Grid>
      {isLoadingImages ?
        <div style={{ textAlign: "center", marginTop: "30px" }}>
          <Grid sx={{ color: 'grey.500' }} spacing={2} direction="row">
            <CircularProgress color="inherit" />
          </Grid>
        </div>
        : SoldImagesData?.items?.rows?.length === 0 ?
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            No Data
          </div>
          :
          <>

            {SoldImagesData?.items?.rows?.length > 0 &&
              <div className="top-reverse-table">
                <Box
                  component="div"
                  className="account-order-table"
                  sx={{
                    backgroundColor: "helpTopicsColors.backgroundColor",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      className="commonButtonStyles"
                      sx={{
                        borderRadius: (theme) =>
                          theme.shape.borderRadiusSecondary(15),
                        // marginLeft: "10px",
                        // marginRight: "10px",
                        margin: '10px'
                      }}

                    >

                      <Typography
                        variant="sectiondata"
                        className="font-white"
                        component="div"
                      >

                        Total Record:{Math.ceil(SoldImagesData?.items?.count) || 0}

                      </Typography>
                    </CustomButton>
                  </Box>
                  <Table aria-label="Pending Orders">
                    <TableHead className="background-light-gray">
                      <TableRow>
                        {dashboardHeader?.map((data, index) => {
                          return (
                            <TableCell
                              key={index}
                              sx={{
                                backgroundColor: "inherit",
                                "&:hover": { backgroundColor: "inherit" },
                                borderBottom: "none",
                              }}
                            >
                              <Typography
                                component="div"
                                variant="mainsectiontitle"
                                className="alignText-center boldFont"
                              >
                                {data?.label == "Royalty" ? `Royalty(${contributorData?.percentage || 0})%` : data?.label}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* FFEAEA color*/}
                      {SoldImagesData?.items?.rows?.map((img, index) => {
                        let manageColor = img?.order?.order_status === '1' ? '#f0ffea' : '#ffeaea';
                        let paidColor = img?.paid ? '#12B347' : '#993300';
                        return <TableRow key={index}
                          style={{ backgroundColor: manageColor, color: 'white', }}
                          sx={{
                            backgroundColor: "inherit",
                          }}
                        >
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <img className='img-fluid' style={{ width: '150px' }} src={makeImageUrl(img?.image_name, img?.rank, img?.shootid)} />
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.image_name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.Download_on ? dayjs(img?.Download_on)?.format("DD MMM YYYY") : "-"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.order_id}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                              style={{ backgroundColor: 'success', color: paidColor }}

                            >
                              {img?.paid ? 'Paid' : 'Unpaid'}
                            </Typography>
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                              style={{ backgroundColor: 'success', color: paidColor }}
                            >
                              {/* dayjs(img?.order?.paid_on)?.format("DD MMM YYYY") */}
                              {/* {row?.order?.paid_on ?formatDate()  : "N/A"} */}
                              {img?.paid_on ? ` ${formatDate(img?.paid_on, 'dd/mm/yyyy')}` : ''}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.quality}-{img?.image_type}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                             {img?.order?.order_status === '1' ? '' : '-'} {currencyConversion(img?.amount)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                             {img?.order?.order_status === '1' ? '' : '-'} {currencyConversion(img?.Discount)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.order?.order_status === '1' ? '' : '-'} {currencyConversion(img?.amount - img?.Discount)}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              backgroundColor: "inherit",
                              "&:hover": { backgroundColor: "inherit" },
                              padding: 0,
                            }}
                          >
                            <Typography
                              component="div"
                              variant="sectiondata"
                              className="alignText-center boldFont"
                            >
                              {img?.order?.order_status === '1' ? '' : '-'}{img?.royality ? currencyConversion(img?.royality) : -'' || "-"}
                              {/* {(img?.amount - img?.Discount) / 2} */}

                            </Typography>
                          </TableCell>
                        </TableRow>
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </div>
            }
          </>
      }

      <Pagination
        itemsPerPage={pageLimit}
        handlePageChange={handlePageChange}
        page={page}
        items={SoldImagesData?.items?.count}
        currentPageFunction={currentPageFunction}
        setPageLimitfunction={setPageLimitfunction}
      />

    </>
  );

}

export default ContributorSoldImages
