import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, InputAdornment, TextField } from "@mui/material";

import {
  formatDate,
  handleFileReader,
  handleParse,
  processCSV,
} from "../../lib/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import CustomInput from "../Common/CustomInput/CustomInput";
import CustomButton from "../Common/Buttons/CustomButton";
import { fileUploadNoc } from "../../services/ImageInformation";
import { AssignNocToImages } from "../../services/contributorNocs";
import CustomAsyncSelect from "../Common/CustomInput/CustomAsyncSelect";

export default function AssignNocModal({
  open,
  handleOpen,
  ImageData,
  getListing,
  nocList,
}) {
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [loading, setLoading] = React.useState(false);
  const images_names =
    ImageData?.map((item) => item?.contributor_upload_image_id)?.join(",") ||
    "";

  const handleFileUpload = async (values, { resetForm }) => {
    try {
      if (values) {
        setLoading(true);
        let noc_ids = values.image_noc_id
        ?.map((item) => item?.value)
        ?.join(',');
        let payload={image_noc_id:noc_ids,image_id:images_names}
        let data = await AssignNocToImages(payload);
        if (data) {
          handleOpen(false);
          resetForm({
            image_noc_id: "",
            image_id: images_names,
          });
          getListing();
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e, "error");
    }
  };
  const getReasonListing = async (inputValue) => {
    if (nocList) {
      let updatedData = nocList?.map((noc, index) => {
        return {
          value: noc?.image_noc_id,
          label: noc?.noc_name,
        };
      });
      return updatedData || [];
    }
  };
  //contributorUploadNoc
  const formik = useFormik({
    initialValues: {
      image_noc_id: "",
      image_id: images_names,
    },
    validationSchema: Yup.object({
      image_noc_id: Yup.array().min(1, 'At least one item is required'),
    }),
    onSubmit: handleFileUpload,
  });

  return (
    <>
      <Dialog
        maxWidth={"sm"}
        open={open}
        onClose={() => {
          if (!loading) {
            handleOpen(false);
          }
        }}
        PaperProps={{
          style: { width: "600px", maxWidth: "100%" }, // Set your desired width here
        }}
      >
        <DialogTitle>Assign NOC to {ImageData?.length} images</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "100%"
            }}
            onSubmit={formik.handleSubmit}
          >
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12}>
                {/* <TextField
                    className="mainsectiontitle main-login-form"
                    fullWidth
                    id="image_noc_id"
                    name="image_noc_id"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    placeholder="Select Nocs"
                   
                    value={formik.values.image_noc_id}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.image_noc_id &&
                      Boolean(formik.errors.image_noc_id)
                    }
                    helperText={
                      formik.touched.image_noc_id &&
                      formik.errors.image_noc_id
                    }
                  >
                    <option key="Select Job Description" value="">
                      Select Noc
                    </option>
                    {nocList &&
                      nocList?.map((noc, index) => (
                        <option key={index} value={noc?.image_noc_id}>
                          {noc?.noc_name}
                        </option>
                      ))}
                  </TextField> */}
                <CustomAsyncSelect
                  promiseOptions={getReasonListing}
                  closeMenuOnSelect={false}
                  controlStyle={{
                    padding: 5,
                    minHeight: "56px",
                  }}
                  cacheOptions={true}
                  value={formik.values.image_noc_id}
                  error={
                    formik.touched.image_noc_id &&
                    Boolean(formik.errors.image_noc_id)
                  }
                  helperText={
                    formik.touched.image_noc_id && formik.errors.image_noc_id
                  }
                  handleChange={(e) => {
                    // setSelectedState(e);
                    formik.setFieldValue("image_noc_id", e);
                  }}
                  width={"100%"}
                  dropDownZIndex={100000}
                  labelShrink={true}
                  label="NOC"
                />
              </Grid>
            </Grid>
            <div
              style={{ display: "flex", justifyContent: "end", marginTop: 15 }}
            >
              <CustomButton
                color={"buttonPrimary"}
                type="submit"
                loading={loading}
                style={{ marginRight: "12px" }}
              >
                Assign
              </CustomButton>
              <CustomButton
                onClick={() => {
                  formik.resetForm({
                    image_noc_id: "",
                  });
                  handleOpen(false);
                }}
                disabled={loading}
              >
                Close
              </CustomButton>
            </div>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
