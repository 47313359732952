import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  QueriesLists: {
    error: {
      isError: false,
      message: "",
    },
    items: [],
    totalCount: 0,
  },
};

export const QueriesListDataSlice = createSlice({
  name: "queriesLists",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setQueriesListsData: (state, action) => {
      state.QueriesLists.items = action.payload;
      state.QueriesLists.totalCount = action.payload?.length;
    },
    setQueriesListsDataError: (state, action) => {
      state.QueriesLists.error = action.payload;
      state.QueriesLists.items = [];
      state.QueriesLists.totalCount = 0;
    },
  
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setQueriesListsData,
  setQueriesListsDataError,

} = QueriesListDataSlice.actions;

export default QueriesListDataSlice.reducer;

export const QueriesListsDataSelector = (state) => state.QueriesLists;
