import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import CustomButton from "../Common/Buttons/CustomButton";
import { BASE_IMAGE_URL } from "../../config/ApiConfig";

export default React.memo(function DeleteConfirmationDialog({
  open,
  onClose,
  imageLink,
  onConfirmation,
  mainImageData,
}) {
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"xs"}
        onClose={onClose}
        open={open}
        className="delete-confirmation-dialog"
      >
        <DialogContent>
          <Box component="div">
            <Typography
              variant="mainpagetitle"
              component="div"
              className="boldFont alignText-center"
            >
              Are you sure you want to delete?
            </Typography>
            <br/>
            {imageLink&&
            <img
              src={imageLink}
              alt="Delete Image"
              className="confirmation-dialog-image"
            />}
            <CustomButton
              className="confirmationButtonStyle"
              fullWidth
              onClick={() => onConfirmation(mainImageData)}
              sx={{
                borderRadius: (theme) => theme.shape.borderRadiusSecondary(15),
              }}
            >
              <Typography
                color="inherit"
                variant="mainsectiontitle"
                component="div"
              >
                Delete
              </Typography>
            </CustomButton>
            <CustomButton
              className="confirmationButtonStyle cancelButton"
              onClick={onClose}
              fullWidth
              sx={{
                borderRadius: (theme) => theme.shape.borderRadiusSecondary(15),
                marginTop: "10px",
              }}
            >
              <Typography
                color="inherit"
                variant="mainsectiontitle"
                component="div"
              >
                Cancel
              </Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
})
